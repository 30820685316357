import { createStore } from 'vuex';
import axios from 'axios';
import {Selects} from '../helpers/select';

export default createStore({
  state: {
    user: {},
    currentCrmObject: "lead",
    params: {},
    exportSelects: {
        object: {},
        list: {},
        params: {}
    },
    api: {},
    selectedObject: "",
    selectedList: "",
    selectedParams: [], 
    multiSelectKey: 0,
    token: "",
    auto: [],
    currentAutoId: false,
    isSpinnerVisible: false,
    settingsBtnDisabled: true
  },
  getters: {
    exportSelects(state) {
      return state.exportSelects;
    },
    user(state) {
        return state.user;
    },
    params(state) {
        return state.params;
    },
    currentCrmObject(state) {
        return state.currentCrmObject;
    },
    api(state) {
        return state.api;
    },
    selectedObject(state) {
        return state.selectedObject;
    },
    selectedList(state) {
        return state.selectedList;
    },
    selectedParams(state) {
        return state.selectedParams;
    },
    multiSelectKey(state) {
        return state.multiSelectKey;
    },
    token(state) {
        return state.token;
    },
    auto(state) {
        return state.auto;
    },
    currentAutoId(state) {
        return state.currentAutoId;
    }, 
    isSpinnerVisible(state) {
        return state.isSpinnerVisible;
    },
    settingsBtnDisabled(state) {
        return state.settingsBtnDisabled;
    }
  },
  mutations: {
    updateExportSelects(state, payload) {
        state.exportSelects = payload;
    },
    updateUser(state, payload) {
        state.user = payload;
    },
    updateParams(state, payload) {
        state.params = payload;
    },
    updateCrmObject(state, payload) {
        state.currentCrmObject = payload;
    },
    updateApiObject(state, payload) {
        state.api = payload;
    },
    updateSelectedObject(state, payload) {
        state.selectedObject = payload;
    },
    updateSelectedList(state, payload) {
        state.selectedList = payload;
    },
    addSelectedProps(state, payload) {
        state.selectedParams.push(payload);
    },
    removeSelectedProps(state, payload) {
        state.selectedParams = state.selectedParams.filter(param => {
            return param != payload;
        })
    },
    clearSelectedProps(state) {
        state.selectedParams = [];
    },
    renderMultiSelectKey(state) {
        state.multiSelectKey += 1;
    },
    updateToken(state, payload) {
        state.token = payload;
    },
    updateAuto(state, payload) {
        state.auto = payload;
    },
    addAuto(state, payload) {
        state.auto.push(payload.data);
    },
    removeAutoById(state, payload) {
        state.auto = state.auto.filter(item => {
            return item.id != payload;
        });
    },
    updateAutoById(state, payload) {
        state.auto = state.auto.reduce((acc, item) => {
            if(item.id == payload.id) {
                item = Object.assign(payload.params, {id: item.id});
            }
            acc.push(item);
            return acc;
        }, []);
    },
    updateCurrentAuto(state, payload) {
        state.currentAutoId = state.auto.reduce((acc, item) => {
            if(item.id == payload) {
                acc = item;
            }   
            return acc;
        }, {});
        state.selectedParams = state.currentAutoId.params;
        state.selectedList = state.currentAutoId.list;
        state.selectedObject = state.currentAutoId.object;
    },
    resetCurrentAuto(state) {
        state.currentAutoId = false;
        state.selectedParams = [];
    },
    changeSpinnerStatus(state) {
        state.isSpinnerVisible = !state.isSpinnerVisible;
    },
    settingsButtonAbled(state) {
        state.settingsBtnDisabled = false;
    },
    settingsButtonDisabled(state) {
        state.settingsBtnDisabled = true;
    }
  },
  actions: {
    async init({ commit, dispatch }) {
        commit('changeSpinnerStatus');
        try {
            commit('updateApiObject', await dispatch('getApiObject'));
            commit('updateUser', (await dispatch('getUserInfo')).data);
            commit('updateParams', await dispatch('getParams'))
            commit('updateExportSelects', await dispatch('getUpdateSelects'));
            dispatch('updateInitialValues');
            commit('updateToken', BX24.appOption.get('token'));
            commit('updateAuto', BX24.appOption.get('auto') || []);
        } finally {
            commit('changeSpinnerStatus');
        }
    },
    async getParams({ dispatch }) {
        const result = {};
        const coroutines = [];
        for (const entity of ['lead', 'company', 'contact', 'deal']) {
            coroutines.push(
                dispatch("callMethodBy", { restMethod: `crm.${entity}.fields`})
                .then(res => result[entity] = res)
            );
        }
        await Promise.all(coroutines);
        return result
    },
    async getApiObject() {
        return axios.create({ baseURL: '/', params: BX24.getAuth() });
    },
    callMethodBy({}, payload  = { params: {} }) {
        return new Promise((resolve) => {
            BX24.callMethod(payload.restMethod, payload.params, result => {
            resolve(result.data());
            });
        });
    },
    async getUpdateSelects({dispatch}) {
        Selects.params.ITEMS = await dispatch('getParamsSelectItems');
        Selects.list.ITEMS = await dispatch('getNoteSendLists');
        return Selects;
    },
    async getNoteSendLists({getters}) {
        return (await getters.api.get('/lists')).data.reduce((acc, item) => {
            acc.push({
                NAME: item.title,
                VALUE: item.id
            });
            return acc;
        }, []);
    },
    async getUserInfo({ getters }) {
        return getters.api.get('/account');
    },
    async getParamsSelectItems({ getters }) {
        let items = [];
        function getTitle(field) {
            const CONSECUTIVE_SPACES = /\s+/g;
            const title = field.formLabel || field.title;
            if (typeof title === 'undefined') return '';
            
            return title.trim().replace(CONSECUTIVE_SPACES, ' ');
        }
        for(let key in getters.params[getters.currentCrmObject]) {
            items.push({
                NAME: getTitle(getters.params[getters.currentCrmObject][key]),
                VALUE: key
            });
        }
        return items;
    },
    async getEmpty( {dispatch} ) {
        const obj = Object.assign({}, Selects); 
        obj.params = Object.assign({}, Selects.params);
        obj.params.ITEMS = await dispatch('getParamsSelectItems');
        return obj;
    },
    async changeCurrentObject({ commit, dispatch }, objectType) {
        commit("updateCrmObject", objectType);
        commit("clearSelectedProps");
        commit("renderMultiSelectKey");
        commit('updateExportSelects', await dispatch('getEmpty'));
    },
    updateParams({ commit }, target) {
        if(target.checked) {
            commit('addSelectedProps', target.value);
        } else {
            commit('removeSelectedProps', target.value)
        }
    },
    async sendExportForm({ getters, commit }, type) {
        commit('changeSpinnerStatus');
        const params = {
            object: getters.selectedObject,
            list: getters.selectedList,
            params: getters.selectedParams
        }
        if(getters.currentAutoId) {
            commit('updateAutoById', {id: getters.currentAutoId.id, params: params});
            await getters.api.patch(`/export/auto/${getters.currentAutoId.id}`, params);
        } else if(type == 'auto') {
            commit('addAuto', await getters.api.post(`/export/auto`, params));
        } else {
            await getters.api.post('/export/manual', params);
        }
        commit('resetCurrentAuto');
        commit('renderMultiSelectKey');
        commit('changeSpinnerStatus');
    },
    async doInstall({ getters, dispatch }) {
        await getters.api.post('/do_install');
        await dispatch('installRobot');
    },
    async installRobot() {
        const originUrl = location.origin;
        const params = {
            'CODE': 'sendMail',
            'HANDLER': `${originUrl}/sendMail`,
            'AUTH_USER_ID': 1,
            'USE_SUBSCRIPTION': '',
            'NAME': 'Отправить письмо по шаблону',
            'USE_PLACEMENT': 'Y',
            'PLACEMENT_HANDLER': `${originUrl}/robotlayout`,
            'PROPERTIES': {
              'id': {
                'Name': 'ID сделки',
                'Type': 'string',
                'Required': 'Y',
                'Multiple': 'N'
              },
              'template_id': {
                'Name': 'Шаблон',
                'Type': 'string',
                'Required': 'Y',
                'Multiple': 'N'
              },
              'params': {
                'Name': 'Параметры',
                'Type': 'string',
                'Required': 'Y',
                'Multiple': 'N'
              }
            }
          };
        BX24.callMethod(
            'bizproc.robot.add',
            params,
            function(result) {
                if(result.error())
                    console.log('Error: ' + result.error());
            }
        );
    },
    async deleteAutoById({ getters, commit }, id) {
        commit('removeAutoById', id);
        await getters.api.delete(`/export/auto/${id}`);
    },
    updateInitialValues({ getters, commit }) {
        commit('updateSelectedObject', getters.exportSelects.object.ITEMS[0].VALUE);
        commit('updateSelectedList', getters.exportSelects.list.ITEMS[0].VALUE);
    },
    async changeToken({ getters, commit }) {
        commit('changeSpinnerStatus');
        try {
            commit('updateUser', (await getters.api.post('/token', {token: getters.token})).data);
            commit('updateAuto', BX24.appOption.get('auto'));
        } catch(e) {
            alert("Ошибка авторизации");
            commit('updateToken', BX24.appOption.get('token'));
        } finally {
            commit('changeSpinnerStatus');
            commit('settingsButtonDisabled');
        }
    }
  },
})
