export const Selects = {
    object: {
        NAME: "Объект",
        VALUE: "object",
        DESCRIPTION: "Выберите, какой объект необходимо экспортировать",
        IMG: require("../assets/crm.png"),
        ITEMS: [
            {
                VALUE: "lead",
                NAME: "Лиды",
                SELECTED: true
            },
            {
                VALUE: "company",
                NAME: "Компании",
                SELECTED: false
            },
            {
                VALUE: "contact",
                NAME: "Контакты",
                SELECTED: false
            },
            {
                VALUE: "deal",
                NAME: "Сделки",
                SELECTED: false
            }
        ],
    },
    list: {
        NAME: "Группа получателей",
        VALUE: "list",
        DESCRIPTION: "Выберите группу получателей, в которую будут передаваться данные",
        IMG: require("../assets/group.png"),
        ITEMS: [],
    },
    params: {
        NAME: "Параметры",
        VALUE: "params",
        DESCRIPTION: "Выберите необходимые параметры для экспорта",
        IMG: require("../assets/list.png"),
        SMALL: "Для выбора нескольких параметров зажмите клавишу Ctrl",
        ITEMS: [],
    }
}

export const mergeAuthData = (data, auth) => {
    const params = new URLSearchParams(data);
    params.append("DOMAIN", auth.domain);
    params.append("AUTH_ID", auth.access_token);
    return params;
  }