<template>
  <div v-if='$route.path != "/install"' id="nav" class="container header">
    <div class="row align-items-center justify-content-between">
      <div class="col-2">
        <img alt="Vue logo" src="./assets/logo.png">
      </div>
      <div class="col-6">
        <div class="row justify-content-center">
          <div class="col-4">
            <router-link to="/auto">Автоматизация</router-link>
          </div>
          <div class="col-4">
            <router-link to="/export">Экспорт</router-link>
          </div>
        </div>
      </div>
      <div class="col-2">
        <router-link to="/settings">Настройки</router-link>
      </div>
    </div>
  </div>
  <router-view/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  mounted() {
    BX24.init(this.init);
  },
  methods: {
    ...mapActions(['init']),
  },
  computed: {
    ...mapGetters(['api']),
  }
};
</script>


<style>
@import url('./styles/index.css');
</style>
