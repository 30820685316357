import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/auto'
  },
  {
    path: '/install',
    component: function () {
      return import('../views/Install.vue')
    }
  },
  {
    path: '/auto',
    name: 'Auto',
    component: function () {
      return import('../views/Auto.vue')
    }
  },
  {
    path: '/export',
    name: 'Export',
    component: function () {
      return import('../views/Export.vue')
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: function () {
      return import('../views/Settings.vue')
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
